import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData} from "../../../framework/src/Utilities";
import { imgArrow, imgArrowSmall, crossIconButton, imgUploadBoxLarge, imgUploadBoxSmall, imgAddress, imgIcon, imgAddressSmall, imgIconSmall } from "./assets";
import React from "react";
import * as Yup from 'yup';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isNumericString, isEnglishString, isArabicString, isArabicContent, formatDateToMonDayYear, formatDateForAcntLtr } from "../../../components/src/utils.web"
import ApiRequest from "../../../components/src/ApiRequest.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start

interface DocumentResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      status: string;
      contract_agreement: {
        url: string;
        id: number;
        filename: string;
      };
      envelope_id: string;
      created_at: string;
      updated_at: string;
      sign_coordinates: string;
      account: {
        data: {
          id: string;
          type: string;
          attributes: {
            first_name: string;
            last_name: string;
            full_phone_number: string;
            country_code: number;
            phone_number: number;
            email: string;
            activated: boolean;
            type: string;
            created_at: string;
            updated_at: string;
            device_id: string | null;
            unique_auth_id: string;
            photo_information: string | null;
            platform: string | null;
            id_proof: string;
            service_type: string;
            country: string;
            language: string;
            profile_image: {
              url: string;
              id: number;
              filename: string;
            };
            document: {
              url: string;
              id: number;
              filename: string;
            };
          };
        };
      };
    };
  };
}

export interface MyFormProps {}
export interface MyFormState {
  initialValues: { [key: string]: string };
  validationSchema: Yup.ObjectSchema<any>;
  htmlParts: string[];
  placeholders: string[];
  arabicHtmlParts: string[];
  arabicPlaceholders: string[];
}

export interface FormErrors {
  contractAmount?: string;
  second_party_english?: string;
  civilian_number?: string;
}

export interface Touched {
  
}

export interface Error {
  
}

interface ApiError {
  message: string;
}

interface ApiResponse {
  errors?: ApiError[];
}



export interface ContractContentData {
  data: {
    id: number;
    created_at: string;
    attributes:{
      description: string;
      arabic_content:string;
      bank_detail:{
        data:{
            attributes: {
              bank_name: string;
              account_number: string;
              swift_code: string;
              account_holder_name:string,
            }
        }
      },
      account_detail:{
        data:{
          attributes:{
            first_name :string;
            last_name :string;
          }
        }
      }
    }
  
};
}

interface IRecentlySavedDoc {
  id: string;
  account_unique_id: string;
  accountType: string;
  lastModified: string;
  dateOpened: string;
  status: string;
}



interface IDocumentHistory {
  id: string
  name: string;
  type: string;
  lastModified: string;
  size: string;
  status: string;
  url: string;
}

interface IAccountLetterForm {
  accountId: string;
  accountType: string;
  dateOpened: string;
  errors: {
    accountId: boolean;
    accountType: boolean;
    dateOpened: boolean;
  };
}

interface IPaginationData {
  current_page: number;
  total_pages: number;
  total_count: number;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  contractContent:string;
  arabicContractContent:string;
  bankDetails:{
    bank_name: string;
    account_number: string;
    swift_code: string;
    account_holder_name:string,
  },
  userDetails:{
    first_name:string,
    last_name:string
  },
  imgArrow:string;
  imgArrowSmall:string;
  crossIconButton:string;
  imgUploadBoxLarge:string;
  imgUploadBoxSmall:string;
  open:boolean;
  imagePreviewUrl:string | null;
  inputRef: any;
  checkedBtn:boolean;
  errorBox : boolean;
  initialValues: { [key: string]: string };
  initialValuesArabic: { [key: string]: string };
  validationSchema: Yup.ObjectSchema<any>;
  validationSchemaArabic: Yup.ObjectSchema<any>;
  htmlParts: string[];
  placeholders: string[];
  arabicHtmlParts: string[];
  arabicPlaceholders: string[];
  contentArabic:string[],
  contentEnglish:string[],
  errorBoxSign:boolean;
  imgAddress:string;
  imgIcon:string;
  imgAddressSmall:string;
  imgIconSmall:string;
  width:number;
  contractAmount: string;
  second_party_english: string;
  second_party_arabic: string;
  civilian_number: string;
  errors: FormErrors;
  isArabic: boolean;
  isSigned:boolean;
  document_status:string;
  pdf_url:File | null;
  preview_url:string;
  docuSign_id: string | null;
  recentlySavedDocList: IRecentlySavedDoc[];
  documentHistoryList: IDocumentHistory[];
  sortBy: string;
  order: string;
  perPage: string;
  paginationData: IPaginationData,
  openFormModal: boolean;
  accountLetterForm: IAccountLetterForm;
  genLetterModal: boolean;
  confirmDraftModal: boolean;
  continueDraftId: string;
  createdLetterId: string;
  createdLetterPdfLink: string;
  successfullyGenModal: boolean;
  deleteAlertModal: boolean;
  deleteItemId: string;
  rememberMe: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getContractContentApiCallId: string = "";
  postContractPdfApiCallId: string = "";
  createEnvelopApiCallId: string = "";
  getPdfUrlApiCallId: string = "";
  apiCallIdSpecificLetter: string = "";
  apiCallIdDocumentHistory: string = "";
  apiCallIdDraftSaved: string = "";
  apiCallIdCreateLetter: string = "";
  apiCallIdUpdateLetter: string = "";
  apiCallIdGenerateLetter: string = "";
  apiCallIdDeleteLetter: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      contractContent:'',
      bankDetails:{
        bank_name: '',
        account_number: '',
        swift_code: '',
        account_holder_name:'',
      },
      userDetails:{ 
        first_name:'',
        last_name:''
      },
      arabicContractContent:'',
      imgArrow:imgArrow,
      imgArrowSmall:imgArrowSmall,
      crossIconButton:crossIconButton,
      imgUploadBoxLarge:imgUploadBoxLarge,
      imgUploadBoxSmall:imgUploadBoxSmall,
      open:false,
      imagePreviewUrl:null,
      inputRef: React.createRef(),
      checkedBtn:false,
      errorBox:false,
      initialValues: {},
      initialValuesArabic: {},
      validationSchema: Yup.object().shape({}),
      validationSchemaArabic: Yup.object().shape({}),
      htmlParts: [],
      placeholders: [],
      arabicHtmlParts: [],
      arabicPlaceholders: [],
      contentArabic:[],
      contentEnglish:[],
      errorBoxSign:false,
      imgAddress:imgAddress,
      imgIcon:imgIcon,
      imgAddressSmall:imgAddressSmall,
      imgIconSmall:imgIconSmall,
      width: window.innerWidth,
      contractAmount: '',
      second_party_english: '',
      second_party_arabic: '',
      civilian_number: '',
      errors: {},
      isArabic: false,
      isSigned:false,
      document_status:'pending',
      pdf_url: null,
      preview_url:'',
      docuSign_id:'',
      recentlySavedDocList: [],
      documentHistoryList: [],
      sortBy: 'newest',
      order: '',
      perPage: '7',
      paginationData: {
       current_page: 1,
       total_pages: 1,
       total_count: 0
      },
      openFormModal: false,
      accountLetterForm: {
        accountId: '',
        accountType: '',
        dateOpened: '',
        errors: {
          accountId: false,
          accountType: false,
          dateOpened: false,
        }
      },
      genLetterModal: false,
      confirmDraftModal: false,
      continueDraftId: '',
      createdLetterId: '',
      createdLetterPdfLink: '',
      successfullyGenModal: false,
      deleteAlertModal: false,
      deleteItemId: '',
      rememberMe: false,
      // Customizable Area End
    };

    // Customizable Area Start  
    this.handleResize = this.handleResize.bind(this);

 

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.createEnvelopApiCallId:
            this.setSignatureResponse(responseJson)
          break;
        case this.getPdfUrlApiCallId:
          this.setPdfUrlResponse(responseJson)
          break;  
      }


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.getContractContentApiCallId) {
          this.getContractContentResponse(message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)));
        } else if (apiRequestCallId == this.postContractPdfApiCallId) {
          
          this.getContractPdfPostResponse(message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)));
          this.setState({loading:false})
         
        } 
      }
    }
    const apiRequestCallIds = {
      [this.apiCallIdDocumentHistory]: this.getDocumentHistoryResponse,
      [this.apiCallIdSpecificLetter]: this.getSpecificLetterResponse,
      [this.apiCallIdDraftSaved]: this.getRecentlySavedDocsResponse,
      [this.apiCallIdCreateLetter]: this.createAccountLetterResponse,
      [this.apiCallIdGenerateLetter]: this.generateAccountLetterResponse,
      [this.apiCallIdUpdateLetter]: this.updateAccountLetterResponse,
      [this.apiCallIdDeleteLetter]: this.deleteAccoutLetterResponse,

      // Add more API call IDs and handlers as needed
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiError = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](apiResponse, apiError);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   
    const queryParams = new URLSearchParams(window.location.search);
    const signatureStatus = queryParams.get('signature_status'); 
    const docuSignId = queryParams.get('docu_sign_id'); 

    if (signatureStatus &&["completed", "delivered", "sent"].includes(signatureStatus)) {
      this.setState({
        document_status: signatureStatus,
        docuSign_id: docuSignId,
        isSigned: true,
        checkedBtn: true
      }, () => {
        this.getPdfUrl();  
      });
    }
    

    this.getContractContent();
    this.getDraftSavedListRequest();
    this.getDocumentHistoryListRequest();
    this.loadSavedFormData();
    window.addEventListener('resize', this.handleResize);

    // Load data from localStorage when component mounts
    const savedContractAmount = await getStorageData('contractAmount');
    const savedSecondaryPartyEnglish = await getStorageData('second_party_english');
    const savedSecondaryPartyArabic = await getStorageData('second_party_arabic');
    const savedCivilianNumber = await getStorageData('civilian_number');

    if (savedContractAmount) this.setState({contractAmount: savedContractAmount});
    if (savedSecondaryPartyEnglish) this.setState({second_party_english: savedSecondaryPartyEnglish});
    if (savedSecondaryPartyArabic) this.setState({second_party_arabic: savedSecondaryPartyArabic});
    if (savedCivilianNumber) this.setState({civilian_number: savedCivilianNumber});
  }
  

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setSignatureResponse = async (responseJson:{signing_url:string, error:string} | null
  ) => {
   if(responseJson)
    { if (responseJson.signing_url) {
      window.location.href = responseJson.signing_url;
    } else if (responseJson.error) {
      this.setState({ loading: false })
      toast.error("There was an issue loading the contract. Please try again.")
      setTimeout(() => {
        window.location.reload();
      }, 3000)
    }}
    else{
      this.setState({ loading: false })
      toast.error("Unable to connect to the DocuSign service. Please check your internet connection and try again.")
      setTimeout(() => {
        window.location.reload();
      }, 4000)
    }
  };
 
  setPdfUrlResponse = async (responseJson: { data: { attributes: { contract_agreement: { url: string } } } }) => {
    const url = responseJson.data.attributes.contract_agreement.url;
    this.setState({ preview_url: url });
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const file = new File([blob], "form.pdf", { type: 'application/pdf' });
        this.setState({ pdf_url: file });
      } else {
        throw new Error("Failed to fetch the file");
      }
    };

    xhr.send();
  }
 

  handleResize() {
    this.setState({ width: window.innerWidth });
  }
  getContractContent = async () => {
    let token= await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContractContentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContractContentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  postContractPdf = async () => {
    const {second_party_english, second_party_arabic, contractAmount, civilian_number, document_status,docuSign_id,pdf_url} = this.state;

    let token= await getStorageData("token");
    const header = {
      token,
    };
    const requestId = await getStorageData("depositRequestId");
    const formData = new FormData();
    formData.append("signed_contract[request_id]", requestId);
    if(pdf_url)formData.append('signed_contract[contract_file]', pdf_url,'form.pdf');
    formData.append("signed_contract[second_party_name]", second_party_english)
    formData.append("signed_contract[second_party_name_ar]", second_party_arabic)
    formData.append("signed_contract[contract_amount]", contractAmount)
    formData.append("signed_contract[civilian_number]", civilian_number)
    formData.append("signed_contract[signature_status]", document_status)
    if(docuSign_id) formData.append("signed_contract[docusign_id]", docuSign_id)
     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postContractPdfApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postContractPdfApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getContractContentResponse=(responseJson:ContractContentData)=>{
    this.setState({contractContent:responseJson.data.attributes.description});
    this.setState({arabicContractContent:responseJson.data.attributes.arabic_content});
    this.setState({bankDetails : responseJson.data.attributes.bank_detail.data.attributes});
    this.setState({userDetails : responseJson.data.attributes.account_detail.data.attributes});
    const contentEnglish = responseJson?.data.attributes.description.split(/<\/?p>/).filter(Boolean);
    const contentArabic = responseJson?.data.attributes.arabic_content.split(/<\/?p>/).filter(Boolean);
    this.setState({contentEnglish : contentEnglish})
    this.setState({contentArabic : contentArabic})
    const { htmlParts, placeholders } = this.extractPlaceholdersAndParts(responseJson?.data.attributes.description)
    this.setState({htmlParts:htmlParts,placeholders:placeholders})
    const initialValues = this.createInitialValues(placeholders);
    this.setState({initialValues:initialValues})
    const validationSchema = this.createValidationSchema(placeholders);
    this.setState({validationSchema:validationSchema})

  }
  getContractPdfPostResponse= async (responseJson?:any)=>{
    if(responseJson.meta.message==="Your contract has been submitted successfully"){
      this.setState({open:true})
      await setStorageData("contractCompleted", true);

      // remove storage data
      await removeStorageData('contractAmount');
      await removeStorageData('second_party_english');
      await removeStorageData('second_party_arabic');
      await removeStorageData('civilian_number');
    }
  }
  navigateToDashBoardPage = async ()=>{
    const goToDashboard = new Message(getName(MessageEnum.NavigationMessage));
    goToDashboard.addData(getName(MessageEnum.NavigationTargetMessage), "DepositRequest");
    goToDashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToDashboard);
    // remove storage data
    await removeStorageData('contractAmount');
    await removeStorageData('second_party_english');
    await removeStorageData('second_party_arabic');
    await removeStorageData('civilian_number');

  }
  handleClose = () => {
    this.setState({ open: false });
  };
  handleClickCloseUploadBox = () =>{
    this.setState({open:false});
  }
 
  goToTermsAndConds=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'TermsConditions'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { successMessage : "CustomForm" })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

  }
  imgUploadBoxOpen = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };
  setCheckBoxValue = (value: boolean) =>{
    this.setState({ checkedBtn: value });

  }


  extractPlaceholdersAndParts(html: string): { htmlParts: string[], placeholders: string[] } {
    const regex = /\[([A-Z\s]+)\]/g;
    const htmlParts: string[] = [];
    const placeholders: string[] = [];
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(html)) !== null) {
      htmlParts.push(html.slice(lastIndex, match.index));
      placeholders.push(match[1].trim());
      lastIndex = regex.lastIndex;
    }
    htmlParts.push(html.slice(lastIndex));
    return { htmlParts, placeholders };
  }

  createInitialValues(placeholders: string[]): { [key: string]: string } {
    const initialValues: { [key: string]: string } = {};
    placeholders.forEach(placeholder => {
      initialValues[placeholder] = '';
    });
    return initialValues;
  }

 
  createValidationSchema(placeholders: string[]): Yup.ObjectSchema<any> {
    const shape: { [key: string]: Yup.Schema<any> } = {};

    placeholders.forEach(placeholder => {
      shape[placeholder] = Yup.string().required(`${placeholder} is required`);
    });

    shape['checkbox'] = Yup.boolean()
      
      .test('checkbox', 'Checkbox must be checked', (value) => {
        if (!value) {
          this.setState({ errorBox: true });
          return false;
        }
        this.setState({ errorBox: false });
        return true;
      });

    return Yup.object().shape(shape);
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof S) => {
    const value = e.target.value;
    let isValid: boolean;
    let isArabic: boolean;
    const maxAmount = 99999999999999999999;
  
    if (value === '') {
      isValid = true;
    } else {
      const isNotJustWhitespace = value.trim() !== '';
  
      switch (field) {
        case 'contractAmount':
          isValid = isNotJustWhitespace && isNumericString(value);
          if (isValid) {
            const numericValue = Number(value);
            isValid = numericValue <= maxAmount;
          }
          break;
        case 'second_party_english':
          isValid = isNotJustWhitespace && isEnglishString(value);
          isArabic = isArabicContent(value)
          break;
        case 'second_party_arabic':
          isValid = isNotJustWhitespace && isArabicString(value);
          isArabic = isArabicContent(value)
          break;
        case 'civilian_number':
          isValid = isNotJustWhitespace && isNumericString(value);
          break;
        default:
          isValid = isNotJustWhitespace;
      }
    }
  
    if (isValid) {
      this.setState((prevState: S) => ({
        ...prevState,
        [field]: value,
        isArabic
      }));

      // Save to localStorage whenever input changes
      setStorageData(field, value)
    }
  }



  generatePDF = async () => {
    const element = document.getElementById('pdf-content');
    if (!element) return;
  
    const padding = 30;
    const fixedWidth = 800; 
    const fixedHeight = 8200;
    
    element.style.padding = `${padding}px`;
  
    element.style.transform = 'none';
    console.log("true","canvascanvas");

    const canvas = await html2canvas(element, {
      scale: 1.5,
      logging: false,
      windowWidth: element.offsetWidth * 1.5,
      windowHeight: element.offsetHeight * 1.5,
      useCORS: true
    });
    console.log(canvas,"canvascanvas");
    
    const pdf = new jsPDF('p', 'px', [fixedWidth + (padding * 2), fixedHeight + (padding * 2)], true);
  
    const imgData = canvas.toDataURL('image/png');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
    canvas.width = pdfWidth;
    canvas.height = pdfHeight;
  
    pdf.addImage(imgData, 'PNG', padding, padding, pdfWidth - (padding * 2), pdfHeight - (padding * 2));
  
    const pdfBlob = pdf.output('blob');
  
    return pdfBlob;
  };

  validateForm = (): boolean => {
    const { contractAmount, second_party_english, second_party_arabic, civilian_number} = this.state;
    const newErrors: FormErrors = {};

    // Check if fields are empty
    if (!contractAmount.trim()) {
      newErrors.contractAmount = 'Contract amount is required';
    } 
    if (!second_party_english.trim()) {
      newErrors.second_party_english = 'Second party (English) is required';
    } 
    if (!second_party_arabic.trim()) {
      newErrors.second_party_english = 'Second party (Arabic) is required';
    } 

    if (!civilian_number.trim()) {
      newErrors.civilian_number = 'Civilian number is required';
    }


   this.setState({errors: newErrors})
    return Object.keys(newErrors).length === 0;
  };

  submitForm = (values:any) => {
    if(this.validateForm()){
      if(values.checkbox){
        this.setState({isSigned:true})
        this.setState({errorBoxSign:false})
        this.setState({loading:true})
        let button = document.getElementById('button');
        button!.style.display = 'none'
        let checkbox = document.getElementById('checkbox');
        checkbox!.style.display = 'none'
          // Replace input fields with their values
          const inputFields = document.querySelectorAll('#pdf-content input');
          inputFields.forEach((input: any) => {
            const span = document.createElement('span');
            span.textContent = input.value + ' ';
            span.style.fontFamily = 'DIN Next LT Arabic Bold';
            span.style.color = 'blue';
            input.parentNode!.replaceChild(span, input);
          });
          this.handleCreateEnvelop()
        }
    }
  
  }

  handleClearError = () => {
    this.setState({
      errorBox: false,
    })
  }

  handleCreateEnvelop = async () => {
    let token= await getStorageData("token");
    let pdf =await this.generatePDF();
    const header = {
      token,
    };

    if (!pdf) {
      return;
  }

    const formData = new FormData();
    formData.append('signed_contract[contract_file]', pdf,'form.pdf');
     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEnvelopApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEnvelop
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPdfUrl = async () => {
    
    let token= await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPdfUrlApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUrl}/${this.state.docuSign_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

submitContract = async ()=>{
  if(this.validateForm()){
    this.setState({ errorBoxSign: false })
    this.setState({ loading: true })
    this.postContractPdf()
  }else{
    this.setState({ errorBox: true })
  }
}

checkDocumentStatus = () => {
  const { document_status } = this.state;
  const validStatuses = ["completed", "delivered", "sent"];
  return validStatuses.includes(document_status.toLowerCase() || '');
};


  handlePaginatioChange = (event: any, next: number) => {
    this.setState((prevState) => ({
      paginationData: {
        ...prevState.paginationData,
        current_page: next
      },
      documentHistoryList: [],
    }), this.getDocumentHistoryListRequest)
  }

  statusBtnColor = (status: string) => {
    const colors: {[key: string]: string} = {
      expired: '#EF4444',
      'fill form': '#1B4FE4',
      'not started': '#F1F5F9'
    }

    return colors[status] || '#1B4FE4';
  }

  isValidDate = (dateString: string): boolean => {
    const regex = /^\d{2}-\d{2}-\d{4}$/;
    if (!regex.test(dateString)) return false;
  
    const [day, month, year] = dateString.split('-').map(Number);
  
    // Create a date object (Note: month is 0-based, so subtract 1)
    const date = new Date(year, month - 1, day);
  
    // Ensure the date is valid
    return date.getDate() === day && (date.getMonth() + 1) === month && date.getFullYear() === year;
  };
  
  handleFormInputChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof IAccountLetterForm;
    const value = event.target.value as string;

  
    this.setState((prevState) => {
      let newValue = value;
      let isError = false;
  
      if (name === 'dateOpened') {
          const formatDate = formatDateForAcntLtr(value);
        if (value === '') {
          newValue = '';
        } else if (this.isValidDate(formatDate)) {
          // If valid date, format it
          newValue = formatDate;
        } else {
          isError = true;
        }
      }

  
      return {
        ...prevState,
        accountLetterForm: {
          ...prevState.accountLetterForm,
          [name]: newValue,
          errors: {
            ...prevState.accountLetterForm.errors,
            [name]: isError
          }
        }
      };
    });
  };

  handleRememberMeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    this.setState({
      rememberMe: checked
    })

    if (checked) {
      await setStorageData('accountLetterForm', JSON.stringify(this.state.accountLetterForm));
    } else {
      await removeStorageData('accountLetterForm');
    }
  };

  loadSavedFormData = async () => {
    const savedForm = await getStorageData('accountLetterForm');
    if (savedForm) {
      this.setState({
        accountLetterForm: JSON.parse(savedForm),
        rememberMe: true,
      });
    }
  };
  
  
  
  
  
  validateInputForm = () => {
    const { accountId, accountType, dateOpened } = this.state.accountLetterForm;
    
    const isDateValid = this.isValidDate(dateOpened);

    this.setState((prev) => {
      return {
        accountLetterForm: {
          ...prev.accountLetterForm,
          errors: {
            accountId: !accountId,
            accountType: !accountType,
            dateOpened: !dateOpened || !isDateValid
          }
        }
      };
    });
  
    return accountId && accountType && dateOpened && isDateValid;
  };

  handleSubmit = (draft: boolean = false) => {
    if (this.validateInputForm()) {
      const { continueDraftId } = this.state;
      // Submit logic here
      if(continueDraftId) {
        this.updateAccountLetterRequest();
      } else {
        this.createAccountLetterRequest(draft)
      }
      
    }
  };

  handleOpenFormModal = () => {
    this.setState({
      openFormModal: true,
    })
  }

  handleGenFreshFormModal = () => {
    this.handleDiscardForm();
    this.setState({
      openFormModal: true,
    })
  }

  handleCloseFormModal = () => {
    this.setState({
      openFormModal: false,
    })
  }

  handleDiscardForm = () => {
    this.setState({
      accountLetterForm: {
        accountId: '',
        accountType: '',
        dateOpened: '',
        errors: {
          accountId: false,
          accountType: false,
          dateOpened: false,
        }
      },
      confirmDraftModal: false,
      continueDraftId: ''
    })
  }


  handleOpenConfirmDraftModal = () => {
    if (this.validateInputForm()) {
    this.setState({
      openFormModal: false,
      confirmDraftModal: true,
    })
  }
  }

  handleCloseConfirmDraftModal = () => {
    this.setState({
      confirmDraftModal: false,
    })
  }

  handleContinueDraftLetter = (id: string) => {
    const selectedDraftLetter = this.state.recentlySavedDocList.find(item => item.id === id);
  
    if (!selectedDraftLetter) {
      toast.error('Selected draft letter not found');
      return;
    }
  
    this.setState((prev) => {
      const updatedForm: IAccountLetterForm = {
        ...prev.accountLetterForm,
        accountId: selectedDraftLetter.account_unique_id || '',
        accountType: selectedDraftLetter.accountType || '',
        dateOpened: selectedDraftLetter.dateOpened || '',
        errors: {
          accountId: false,
          accountType: false,
          dateOpened: false
        }
      };
  
      return {
        continueDraftId: selectedDraftLetter.id,
        openFormModal: true,
        accountLetterForm: updatedForm
      };
    });
  }

  handleOpenGenerateLetterModal = () => {
    this.setState({
      genLetterModal: true,
    })
  }

  handleCloseGenerateLetterModal = () => {
    this.setState({
      genLetterModal: false,
    })
  }

  handleCloseSuccessfullyGenModal = () => {
    this.setState({
      successfullyGenModal: false,
    })
  }


  handleOpenDeleteAlertModal = (id: string) => {
    this.setState({
      deleteItemId: id,
      deleteAlertModal: true,
    })
  }

  handleCloseDeleteAlertModal = () => {
    this.setState({
      deleteAlertModal: false,
    })
  }

  handleGenerateBtnFunc = () => {
    const { rememberMe } = this.state;
    if (rememberMe) {
      this.handleOpenFormModal();
    } else {
      this.handleGenFreshFormModal();
    }
  }

  handleSortingChange = (value: string) => {
    this.setState({
      sortBy: value
    }, this.getDocumentHistoryListRequest)
  }

  // API requet for Account Letter

  getSpecificLetterRequest = async () => {
    const { createdLetterId } = this.state;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.acountLetterEndpoint}/${createdLetterId}/show_letter`,
      method: "GET",
    });

    this.apiCallIdSpecificLetter = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDocumentHistoryListRequest = async () => {
    const { sortBy, paginationData, perPage } = this.state;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };
    const params = new URLSearchParams();

    if (sortBy) params.append("sort_order", sortBy);
    if (paginationData.current_page) params.append("page", paginationData?.current_page.toString());
    if (perPage) params.append("per_page", perPage);

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.acountLetterEndpoint}/show?${params.toString()}`,
      method: "GET",
    });

    this.apiCallIdDocumentHistory = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      documentHistoryList: []
    })
  };

  getDraftSavedListRequest = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.acountLetterEndpoint}/recent_drafts`,
      method: "GET",
    });

    this.apiCallIdDraftSaved = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createAccountLetterRequest = async (draft: boolean = false) => {
    const { accountId, accountType, dateOpened } = this.state.accountLetterForm;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const payload = {
      "account_confirmation_letter": {
        "account_unique_id": accountId,
        "account_type": accountType,
        "date_of_account_opened": dateOpened,
        "save_as_draft": draft
      }
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.acountLetterEndpoint}`,
      payload: JSON.stringify(payload),
      method: "POST",
    });

    this.apiCallIdCreateLetter = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAccountLetterRequest = async () => {
    const { continueDraftId, accountLetterForm } = this.state;
    const { accountId, accountType, dateOpened } = accountLetterForm;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const payload = {
      "account_confirmation_letter": {
        "account_unique_id": accountId,
        "account_type": accountType,
        "date_of_account_opened": dateOpened,
        "save_as_draft": false,
      }
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.acountLetterEndpoint}/${continueDraftId}`,
      payload: JSON.stringify(payload),
      method: "PUT",
    });

    this.apiCallIdUpdateLetter = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateAccountLetterRequest = async () => {
    const { createdLetterId } = this.state;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const payload = {
      "action_type": "generate"
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.acountLetterEndpoint}/${createdLetterId}/confirm_generation`,
      payload: JSON.stringify(payload),
      method: "POST",
    });

    this.apiCallIdGenerateLetter = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteAccountLetterRequest = async (confirmationId: string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.acountLetterEndpoint}/${confirmationId}`,
      method: "DELETE",
    });

    this.apiCallIdDeleteLetter = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Api response for Account Letter
  getSpecificLetterResponse = (responseJson: any, errorResponse: any) => {
    if (this.handleErrorReturn(responseJson as ApiResponse, errorResponse)) {
      return;
    }
    console.log("accountLetterInfo", responseJson)
    if (responseJson && responseJson.id) {
      const accountLetterInfo: IAccountLetterForm = {
        accountId: responseJson.account_unique_id,
        accountType: responseJson.account_type,
        dateOpened: formatDateForAcntLtr(responseJson.date_of_account_opened),
        errors: {
          accountId: false,
          accountType: false,
          dateOpened: false
        }
      }

      console.log({accountLetterInfo})

      this.setState({
        genLetterModal: false,
        accountLetterForm: accountLetterInfo
      }, this.handleOpenFormModal);
  
    } else {
      toast.info('No account confirmation letters found.');
    }
  }

  getDocumentHistoryResponse = (responseJson: any, errorResponse: any) => {
    
    if (responseJson.account_confirmation_letters && Array.isArray(responseJson.account_confirmation_letters) && responseJson.account_confirmation_letters.length > 0) {
      const documentHistoryList: IDocumentHistory[] = responseJson.account_confirmation_letters.map((item: any) => {
        return {
          id: item.id,
          name: `Account Confirmation Letter.pdf`,
          type: "PDF",
          lastModified: formatDateToMonDayYear(item.todays_date),
          size: item.file_size,
          status: item.confirmed,
          url: item.pdf_file_link
        };
      });
  
      this.setState({ 
        documentHistoryList,
        paginationData: responseJson.pagination || { current_page: 1, total_pages: 1, total_count: 0 }
      });
  
    } else {
      toast.info('No account confirmation letters found.');
      this.setState({ 
        documentHistoryList: [],
        paginationData: responseJson.pagination || { current_page: 1, total_pages: 1, total_count: 0 }
      });
    }
  };

  getRecentlySavedDocsResponse = (responseJson: any, errorResponse: any) => {
  
    if (!Array.isArray(responseJson)) {
      this.setState({ recentlySavedDocList: [] });
      return;
    }
  
    const recentlySavedDocList: IRecentlySavedDoc[] = responseJson.map((item: any) => ({
      id: item.id.toString(),
      account_unique_id: item.account_unique_id,
      accountType: item.account_type,
      lastModified: formatDateToMonDayYear(item.created_at),
      dateOpened: formatDateForAcntLtr(item.date_of_account_opened),
      status: item.status
    }));
  
    this.setState({ recentlySavedDocList });
  
  };

  createAccountLetterResponse = (responseJson: any, errorResponse: any) => {
    if (this.handleErrorReturn(responseJson as ApiResponse, errorResponse)) {
      return;
    }
  
    if (responseJson && responseJson.message) {
      if (responseJson.id && responseJson.pdf_file_link) {
        // Handle the case where a new letter is created
        const createdLetterId = responseJson.id;
        const createdLetterPdfLink = responseJson.pdf_file_link;
  
        this.setState(
          { 
            openFormModal: false, 
            confirmDraftModal: false, 
            createdLetterId, 
            createdLetterPdfLink
          }, 
          () => {
            this.handleOpenGenerateLetterModal();
          }
        );
  
        toast.success(responseJson.message);
      } else if (responseJson.message.includes("Draft saved")) {
        this.setState(
          { 
            openFormModal: false, 
            confirmDraftModal: false,
            genLetterModal: false,
          },
          () => {
            this.getDocumentHistoryListRequest();
            this.getDraftSavedListRequest()
          }
        );
  
        toast.success(responseJson.message);
      } 
    } else {
      toast.error('Unexpected response format');
    }
  };

  generateAccountLetterResponse = (responseJson: any, errorResponse: any) => {
    if (this.handleErrorReturn(responseJson as ApiResponse, errorResponse)) {
      return;
    }
  
    if (responseJson && responseJson.message) {
      if (responseJson.id && responseJson.pdf_file_link) {
        // Handle the case where a new letter is created
        const createdLetterId = responseJson.id;
        const createdLetterPdfLink = responseJson.pdf_file_link;
  
        this.setState(
          { 
            openFormModal: false, 
            confirmDraftModal: false,
            genLetterModal: false, 
            createdLetterId, 
            createdLetterPdfLink,
            successfullyGenModal: true
          },
          () => {
            this.getDocumentHistoryListRequest();
            this.getDraftSavedListRequest();
          }
        );
  
        toast.success(responseJson.message);
      }
    } else {
      toast.error('Unexpected response format');
    }
  };

  updateAccountLetterResponse = (responseJson: any, errorResponse: any) => {
    if (this.handleErrorReturn(responseJson as ApiResponse, errorResponse)) {
      return;
    }
  
    if (responseJson && responseJson.message) {
      if (responseJson.letter_id && responseJson.pdf_file_link) {
        const createdLetterId = responseJson.letter_id;
        const createdLetterPdfLink = responseJson.pdf_file_link;
  
        this.setState(
          { 
            openFormModal: false, 
            confirmDraftModal: false, 
            createdLetterId, 
            continueDraftId: '',
            createdLetterPdfLink
          }, 
          () => {
            this.handleOpenGenerateLetterModal();
            this.getDocumentHistoryListRequest();
            this.getDraftSavedListRequest()
          }
        );
  
        toast.success(responseJson.message);
      }
    } else {
      toast.error('Unexpected response format');
    }
  };

  deleteAccoutLetterResponse = (responseJson: any, errorResponse: any) => {
    if (this.handleErrorReturn(responseJson as ApiResponse, errorResponse)) {
      return;
    }
  
    if (responseJson && responseJson.message) {
      if (responseJson.message.includes("deleted successfully")) {
        toast.success(responseJson.message)
      }
    } else {
      toast.error('Sorry! Something went wrong.');
    }
    this.handleCloseDeleteAlertModal();
    this.getDocumentHistoryListRequest();
  }

  handleErrorReturn(responseJson: ApiResponse | null, errorResponse: any): boolean {
    this.handleCloseConfirmDraftModal();
    this.handleCloseDeleteAlertModal();
    this.handleCloseFormModal();
    this.handleCloseGenerateLetterModal();
    this.handleCloseSuccessfullyGenModal();
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
      return true;
    }
  
    if (responseJson?.errors && responseJson.errors.length > 0) {
      const errorMessage = responseJson.errors[0].message || 'An error occurred';
      toast.error(errorMessage);
      return true;
    }
  
    return false;
  }



  // Customizable Area End
}
