import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { downloadIcon, userImage } from "./assets";
interface Account {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: string | null;
  full_name: string;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  is_local_admin: boolean;
  id_proof: string;
  service_type: string;
  country: string;
  photo_information: string | null;
  language: string;
}
interface Attributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string | null;
  status : string | null;
  is_read: boolean;
  read_at: string | null;
  created_at: string;
  updated_at: string;
  account: Account;
}
interface Notification {
  id: string;
  type: string;
  attributes: Attributes;
}
export type TStatus = "pending" | "rejected" | "approved" | "submitted" | "completed" | "fill form" | "not started";
export interface IPaginationData {
  currentPage: number;
  nextPage: number;
  prevPage: number;
  totalPages: number;
  currentCount: number;
  totalCount: number;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  loading: boolean;
  totalProfitPopup: boolean;
  activeTab: number;
  isDialogOpen: boolean;
  anchorEl : HTMLElement | null;
  selectedNotificationId: string | null;
  data: Notification[];
  profitList: {label: string, value: string}[];
  investmentData: {
    name: string,
    percentage: string,
    total: string,
    image: string,
    color: string
  }[];
  dummyData: {
    image:string,
    name: string,
    receivedMoney: string,
    status: string,
  }[],
  dummyData2:any,
  graphWidth: number;
  transactionsActivity: {
    date: string,
    type: string,
    amount: string,
    balance: string,
    amountColor: string,
  }[];
  assestActivity: {
    class: string,
    current: string,
    target: string,
    balance: string,
    currentColor: string,
    amountColor: string,
  }[];
  startDate: string;
  inputRef: any;
  weekOption:boolean,
  searchPopUp:boolean,
  transactionList:boolean,
  paginationData: IPaginationData,
  activeData: {
    first: number
  }
  // Customizable Area End
}
interface SS {}

export default class WeeklyPerformanceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      graphWidth: 1405,
      token: "",
      loading: false,
      startDate: "",
      activeTab: 0,
      totalProfitPopup: false,
      isDialogOpen : false,
      anchorEl:null,
      selectedNotificationId: null,
      data: [],
      transactionList:false,
      paginationData: {
        currentPage: 1,
        nextPage: 2,
        prevPage: 0,
        totalPages: 5,
        currentCount: 10,
        totalCount: 50 
      },
      dummyData: [
        {
          image:userImage,          name: "John Doe",          receivedMoney: "OMR 1000",          status: "Received" },
        {
          image:userImage,    name: "Jane Smith",
          receivedMoney: "OMR 5000",          status: "Pending",        },
        {
          image:userImage,     name: "John Doe",
          receivedMoney: "OMR 10000",
          status: "Received",        },
        {
          image:userImage,
          name: "Jane Smith",
          receivedMoney: "OMR 5050",
          status: "Pending",
        },
        {
          image:userImage,
          name: "John Doe",
          receivedMoney: "OMR 10050",
          status: "Received",
        },{ image:userImage, name: "Jane Smith", receivedMoney: "OMR 5500", status: "Pending" },
        {
          image:userImage,
          name: "John Doe",          receivedMoney: "OMR 11000",
          status: "Received",        },
        {
          image:userImage,        name: "Jane Smith",   receivedMoney: "OMR 5000",   status: "Pending"
        },
        {
          image:userImage, name: "John Doe",  receivedMoney: "OMR 100",
          status: "Received"
        },
        {
          image:userImage,          name: "Jane Smith",
          receivedMoney: "OMR 50",          status: "Pending",
        },
      ],
      dummyData2: [
        { id: 1, value: 150, label: "Successful Trade", icon: "O" },
        { id: 2, value: 100, label: "Open Trade", icon: "O" },
        { id: 3, value: 200, label: "Open Trade", icon: "O" },
      ],
      profitList: [
        {
          label: "This Week",
          value: "This Week"
        },
        {
          label: "This Month",
          value: "This Month"
        },
      ],
      inputRef: React.createRef(),
      searchPopUp:false,
      weekOption: false,
      activeData: {
        first: 0,
      },
      investmentData: [
        {
          name: "Total Investments",
          percentage: "-18%",
          total: "$357,000",
          image: downloadIcon,
          color: "#EF4444"
        },
        {
          name: "Total Profits",
          percentage: "+2.5%",
          total: "$400,000",
          image: downloadIcon,
          color: "#F59E0B"
        },
        {
          name: "Total Returns",
          percentage: "+25%",
          total: "$390,000",
          image: downloadIcon,
          color: "#10B981"
        },
        {
          name: "Avg in Return",
          percentage: "+50%",
          total: "$399,000",
          image: downloadIcon,
          color: "#1B4FE4"
        },
      ],
      transactionsActivity: [
        {
          date: "Jan 04, 2024",
          type: "Withdraw",
          amount: "-15000",
          balance: "$64900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "-21000",
          balance: "$85900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Withdraw",
          amount: "+20000",
          balance: "$74900",
          amountColor: "#10B981",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "+20000",
          balance: "$84900",
          amountColor: "#10B981",
        },
      ],
      assestActivity: [
        {
          class: "Lorem",
          current: "10%",
          target: "15%",
          balance: "$75000",
          currentColor: "#EF4444",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#F59E0B",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#10B981",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#EF4444",
          amountColor: "#EF4444",
        },
      ]

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    window.addEventListener('resize', this.getChartWidth)
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  stepColor = (status: TStatus) => {
    const colors: {[key: string]: string} = {
      Pending: "#F59E0B",
      Received: '#10B981',
      completed: '#10B981',
      rejected: '#EF4444',
      submitted: '#10B981',
      'fill form': '#1B4FE4',
      'not started': '#F1F5F9'
    }
    return colors[status] || '#F1F5F9';
  }

  getChartWidth = () => {
    const screenWidth = window.innerWidth;
    if(screenWidth <= 900) {
      this.setState({graphWidth: screenWidth - 70})
    }else
    this.setState({graphWidth: screenWidth - 400})
  };

  handleCalendarOpen = () => {
    if (this.state.inputRef.current) {
      this.state.inputRef.current.showPicker();
    }
  };

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  handleWeekOption = () => {
    this.setState({weekOption: !this.state.weekOption})
  }

  handleActiveTab = (index: number) => {
    this.setState({activeTab: index})
  }

  handleTransactionList = () => {
    this.setState({transactionList: !this.state.transactionList})
  }

  listDataOftrans = () => {
    this.setState({transactionList: false})
  }
  
  handleSearchPopUp = () => {
    this.setState({searchPopUp: !this.state.searchPopUp})
  }
  // Customizable Area End
}
