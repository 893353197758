import React from "react";
import {
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  StylesProvider,
  styled,
} from "@material-ui/core/styles";

import ContactusController, { Props, configJSON } from "./ContactusController";
import { buttonDown, checkIcon, crossIcon } from "./assets";
import { Formik } from "formik";

// Customizable Area Start
import { withTranslation } from "react-i18next";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .headingBox": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "8px",
      flexDirection: "column",
      width: "100%",
    },
    "& .headOne": {
      [theme.breakpoints.down(598)]: {
        fontSize: "18px",
      },
      fontSize: "24px",
      color: "#334155",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .headTwo": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
      },
      fontSize: "16px",
      color: "#334155",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .subjectBox": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-starat",
      alignItems: "flex-start",
      gap: "6px",
      flexDirection: "column",
      width: "100%",
    },
    "& .detailBox": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-starat",
      alignItems: "flex-start",
      gap: "6px",
      width: "100%",
      flexDirection: "column",
    },
    "& .subject": {
      [theme.breakpoints.down(598)]: {},
      fontSize: "14px",
      color: "#334155",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .detail": {
      [theme.breakpoints.down(598)]: {},
      fontSize: "14px",
      color: "#334155",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .subject_input": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      padding: "10px 8px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      color: "#1E293B",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
    },
    "& .subject_input2": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      padding: "10px 8px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      color: "#1E293B",
      borderRadius: "8px",
      border:"none",
      outline:"none"
    },
    "& .detail_input": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      padding: "10px 8px",
      paddingBottom: "104px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#94A3B8",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
    },
    "& .submitBox": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      width: "100%",
    },
    "& .sub_mit": {
      [theme.breakpoints.down(598)]: {},
      width: "50%",
      padding: "10px 16px",
      borderRadius: "8px",
      backgroundColor: "#1B4FE4",
      border: "1px solid #CBD5E1",
      color: "#FFFFFF",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .sub_mit2": {
      [theme.breakpoints.down(598)]: {},
      width: "50%",
      padding: "10px 16px",
      borderRadius: "8px",
      backgroundColor: "#F1F5F9",
      border:"none",
      color: "#64748B",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .cross_box": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      width: "100%",
    },
    "& .success_box": {
      [theme.breakpoints.down(598)]: {
        justifyContent: "center",
        alignItems: "center",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    "& .successTextBox": {
      [theme.breakpoints.down(598)]: {},
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "90%",
      flexDirection: "column",
      gap: "16px",
    },
    "& .textOne": {
      [theme.breakpoints.down(598)]: {
        fontSize: "18px",
      },
      fontSize: "24px",
      color: "#334155",
      textAlign: "center",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .textTwo": {
      [theme.breakpoints.down(598)]: {
        fontSize: "14px",
      },
      fontSize: "18px",
      color: "#334155",
      fontFamily: "DIN Next LT Arabic Regular",
      textAlign: "center",
    },
    "& .backBox": {
      [theme.breakpoints.down(598)]: {
        width: "80%",
      },
      [theme.breakpoints.down(321)]: {
        width: "70%",
      },
      width: "30%",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .backBox2": {
      [theme.breakpoints.down(1570)]: {
        width: "35%",
      },
      [theme.breakpoints.down(598)]: {
        width: "80%",
      },
      [theme.breakpoints.down(321)]: {
        width: "70%",
      },
      width: "30%",
    },
    "& .cross2": {
      [theme.breakpoints.up(598)]: {
        display: "none",
      },
      [theme.breakpoints.down(598)]: {
        display: "flex",
        alignItems: "end",
      },
    },
    "& .checkIcon": {
      [theme.breakpoints.down(598)]: {
        width: "48px",
        height: "48px",
        position: "absolute",
      },
    },
    "& .describeBox": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
      },
      width: "100%",
      height: "104px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      padding: "10px 16px",
      color: "#1E293B",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      resize: "none",
    },
    "& .form": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "25px",
    },
    "& .DirectionAr":{
      "& .MuiInputBase-input":{
        direction: "ltr",
      }
    },
    "& .english":{
      "& .MuiInputBase-input":{
        direction: "ltr",
      }
    },
  }));

  render() {
    return (
      // Customizable Area Start
      <this.MainWrapper>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            {this.state.successPopup && (
              <Box style={webStyle2.boxContainer}>
                <Box sx={webStyle2.backBox2} className="backBox2">
                  <Box className="cross_box" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                    <img src={crossIcon} onClick={this.closeSuccessPopup} />
                  </Box>
                  <Box className="success_box">
                    <img src={checkIcon} className="checkIcon" />
                  </Box>
                  <Box className="successTextBox">
                    <Typography className="textOne">
                      {this.props.i18n?.t('msgReceive')}
                    </Typography>
                    <Typography className="textTwo">
                    {this.props.i18n?.t('msgThank')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {this.state.handleOpenPopup && (
              <Formik
                data-test-id="formik"
                initialValues={{
                  name: this.state.name,
                  email: this.state.email,
                  countryCode:  this.handleCondition(this.state.countryCode?.[0] === "+" , this.state.countryCode , `+${this.state.countryCode}`),
                  contactNumber: this.state.phoneNumber,
                  subject: "",
                  details: "",
                }}
                validationSchema={this.handleCondition(this.props.i18n?.language === 'ar', this.validationSchemaAr, this.validationSchema)}
                onSubmit={this.submitContactUsRequest}
              >
                {({
                  handleSubmit,
                  touched,
                  errors,
                  handleBlur,
                  setFieldValue,
                  values,
                  handleChange,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="form"
                  >
                    <Box style={webStyle2.boxContainer}>
                      <Box style={{...webStyle2.backBox,
                         direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}} className="backBox">
                        <Box className="cross_box">
                          <img src={crossIcon} onClick={this.handleOpenPopup} />
                        </Box>
                        <Box className="headingBox">
                          <Typography className="headOne">
                            {this.props.i18n?.t('reachUs')}
                          </Typography>
                          <Typography className="headTwo">
                            {this.props.i18n?.t('forHelp')}
                          </Typography>
                        </Box>
                        <Box className="subjectBox">
                          <Typography className="subject">{this.props.i18n?.t('name')}</Typography>
                          <input
                            data-test-id="name"
                            value={values.name}
                            onBlur={handleBlur}
                            name="name"
                            disabled={!!values.name}
                            className="subject_input"
                            placeholder={this.props.i18n?.t('enterName')}
                            onChange={(event) => {
                              const alpha = event.target.value.replace(
                                /\d/g,
                                ""
                              );
                              setFieldValue("name", alpha);
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "name")}
                        </Box>
                        <Box className="subjectBox">
                          <Typography className="subject">
                          {this.props.i18n?.t('emailAddress')}
                          </Typography>
                          <input
                            style={{ border: this.handleCondition(
                            this.getErrorMessage(touched, errors, "email"),
                            "1px solid #F87171",
                            "1px solid #ced4da"
                            )}}
                            data-test-id="email"
                            value={values.email}
                            onBlur={handleBlur}
                            name="email"
                            className="subject_input"
                            placeholder={this.props.i18n?.t('enterEmail')}
                            onChange={(event) =>{
                              this.setState({email: event.target.value})
                              setFieldValue("email", event.target.value)
                            }}
                          />
                          {this.getErrorMessage(touched, errors, "email")}
                        </Box>
                        <Box className="subjectBox">
                          <Typography className="subject">
                          {this.props.i18n?.t('contactNumber')}
                          </Typography>
                          <Box style={{...webStyle2.contactInputBox, border: this.handleCondition(
                              this.getErrorMessage(touched, errors, "contactNumber"),
                              "1px solid #F87171",
                              "1px solid #ced4da"
                            )}}>
                            <TextField
                              style={{...webStyle2.phoneSelectStyle}}
                              fullWidth
                              name="countryCode"
                              data-test-id="phonecode"
                              onClick={this.handleCodeBox}
                              value={values.countryCode}
                              onChange={(e)=>{
                                this.setState({countryCode: e.target.value})
                                setFieldValue("countryCode", e.target.value)
                              }}
                              className={`${this.handleCondition(this.props.i18n?.language === "ar" , "DirectionAr" , "english")}`}
                              InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                      src={buttonDown}
                                      alt="dropdown"
                                      onClick={this.handleCodeBox}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <input
                              data-test-id="contactNo"
                              onBlur={handleBlur}
                              name="contactNumber"
                              className="subject_input2"
                              placeholder={this.props.i18n?.t('enterContact')}
                              value={values.contactNumber}
                              maxLength={11}
                              minLength={7}
                              onChange={(event) => {
                                const numericValue = event.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                this.setState({phoneNumber:numericValue});
                                setFieldValue("contactNumber", numericValue);
                              }}
                            />
                          </Box>
                          <Box style={webStyle2.contactDropBox}>
                            {this.state.codeBox ? (
                              <Box
                                style={{
                                  zIndex: 2,
                                  width: "100%",
                                  border: "1px solid black",
                                  position: "absolute",
                                  backgroundColor: "white",
                                }}
                              >
                                <input
                                  style={{
                                    zIndex: 3,
                                    padding: "20px",
                                    width: "100%",
                                    top: 100,
                                  }}
                                  onChange={this.handleSearchChange}
                                  placeholder={this.props.i18n?.t('searchCode')}
                                />
                                <Box
                                  style={webStyle2.contactBB}
                                >
                                  {configJSON.countryCodeList
                                    .filter((item: { value: string }) =>
                                      item.value.includes(this.state.searchData)
                                    )
                                    .map(
                                      (
                                        item: { value: string; label: string },
                                        index: number
                                      ) => (
                                        <MenuItem
                                          key={index}
                                          value={item.value}
                                          onClick={() => {
                                            setFieldValue(
                                              "countryCode",
                                              item.label
                                            );
                                            this.setState({ codeBox: false });
                                          }}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      )
                                    )}
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                          {this.getErrorMessage(
                            touched,
                            errors,
                            "contactNumber"
                          )}
                        </Box>
                        <Box className="subjectBox">
                          <Typography className="subject">{this.props.i18n?.t('subject')}</Typography>
                          <input
                          style={{...webStyle2.contactInputBox, border: this.handleCondition(
                            this.getErrorMessage(touched, errors, "subject"),
                            "1px solid #F87171",
                            "1px solid #ced4da"
                          )}}
                            value={values.subject}
                            onBlur={handleBlur}
                            name="subject"
                            className="subject_input"
                            placeholder={this.props.i18n?.t('enterSubject')}
                            onChange={handleChange}
                          />
                          {this.getErrorMessage(touched, errors, "subject")}
                        </Box>
                        <Box className="detailBox">
                          <Typography className="detail">{this.props.i18n?.t('details')}</Typography>
                          <textarea
                           style={{...webStyle2.contactInputBox, border: this.handleCondition(
                            this.getErrorMessage(touched, errors, "details"),
                            "1px solid #F87171",
                            "1px solid #ced4da"
                          )}}
                            value={values.details}
                            onBlur={handleBlur}
                            name="details"
                            placeholder={this.props.i18n?.t('enterDetails')}
                            className="describeBox"
                            onChange={handleChange}
                          ></textarea>
                          {this.getErrorMessage(touched, errors, "details")}
                        </Box>
                        <Box className="submitBox" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'ltr', "")}}>
                          <button
                            type="submit"
                            className={"sub_mit"}
                          >
                            {this.props.i18n?.t('submit')}
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            )}
          </ThemeProvider>
        </StylesProvider>
      </this.MainWrapper>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const webStyle2 = {
  contactBB:{
    height: "fit-content",
    maxHeight: "300px",
    overflow: "scroll",
  },
  contactInputBox: { display: "flex", gap: "6px", width: "100%", border:"1px solid #CBD5E1", borderRadius: "8px", justifyContent: "center", alignItems: "center"},
  contactDropBox: { position: "relative" as "relative", width: "100%" },
  boxContainer: {
    width: "100%",
    position: "fixed" as "fixed",
    top: 0,
    right: 0,
    height: "100%",
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.6)",
  },
  phoneSelectStyle: {
    backgroundColor: "#EFF6FF",
    width: "fit-content",
    maxWidth:"5.75rem",
    fontSize:"16px",
    fontFamily:"DIN Next LT Arabic Regular",
    height:"30px",
    marginLeft: "4px",
    display:"flex",
    gap:"5px"
  },
  backBox: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "#FFF",
    top: 0,
    zIndex: 31,
    padding: "24px 32px",
    maxHeight: "80%",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  backBox2: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "#FFFFFF",
    top: 0,
    zIndex: 31,
    padding: "24px 32px 52px 32px",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
};
export const ContactusWeb = withTranslation()(Contactus)
// Customizable Area End
